<template>
  <CollapsibleLinks>
    <template #trigger="{ toggle }">
      <a
        class="font-bold text-primary hover:text-primary-dark text-lg"
        @click.stop.prevent="toggle"
        >{{ $t("footer.useful") }}</a
      >
    </template>
    <div class="flex flex-col mb-3 gap-1 text-sm">
      <a href="/static/aszf" class="text-white">{{ $t("footer.tos") }}</a>
      <a href="/static/gyik" class="text-white">{{ $t("footer.faq") }}</a>
      <NuxtLink
        :to="localePath({ name: 'aboutUs' }) + '#impressum'"
        class="text-white"
      >
        {{ $t("footer.impressum") }}
      </NuxtLink>
      <a
        href="https://docs.google.com/forms/d/1xJPWujBcMqY8fHRiIYwd5AMTPfyfN2q1uDJO3b4ZVLI/viewform"
        class="text-white"
        target="_blank"
      >
        {{ $t("footer.contacts") }}
      </a>
    </div>
  </CollapsibleLinks>
</template>

<script>
import CollapsibleLinks from "./CollapsibleLinks.vue";

export default {
  components: { CollapsibleLinks },
};
</script>
