<template>
  <div class="bg-[#33495d] py-14 is-hidden-mobile">
    <div class="container">
      <div class="columns is-multiline" style="margin: 0">
        <div
          class="column is-full justify-center align-middle flex-1 flex flex-col"
        >
          <div class="flex align-middle justify-center pb-3 text-5xl">
            <IconComponent class="text-white" icon="map-marker-alt" />
          </div>
          <div class="flex align-middle justify-center text-white text-2xl">
            {{ $t("personList.cities") }}
          </div>
        </div>
        <div class="column is-one-quarter pt-4">
          <NuxtLink
            v-if="profession"
            :to="
              localePath({
                name: 'professionals',
                params: { profession: profession.seoName, city: 'budapest' },
              })
            "
            class="flex text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mb-4"
          >
            {{ $t("personList.budapest") }}
          </NuxtLink>
          <NuxtLink
            v-else
            :to="
              localePath({
                name: 'countyCity',
                params: { county: 'pest-megye', city: 'budapest' },
              })
            "
            class="flex text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mb-4"
          >
            {{ $t("personList.budapest") }}
          </NuxtLink>
          <div v-for="county in counties1" :key="county.countySeoName">
            <CityLink :county="county" :profession="profession" />
          </div>
        </div>
        <div
          v-for="(countyList, index) in restOfCounties"
          :key="index"
          class="column is-one-quarter pt-4"
        >
          <div v-for="county in countyList" :key="county.countySeoName">
            <CityLink :county="county" :profession="profession" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CityLink from "./CityLink.vue";

export default {
  components: {
    CityLink,
  },
  computed: {
    counties() {
      return this.$store.getters["data/counties"];
    },
    profession() {
      return this.$store.getters["listPage/profession"];
    },
    filteredCounties() {
      return this.counties.filter((x) => {
        return x.seoName !== "budapest";
      });
    },
    counties1() {
      return this.filteredCounties.slice(0, 5);
    },
    restOfCounties() {
      return [
        this.filteredCounties.slice(5, 10),
        this.filteredCounties.slice(10, 15),
        this.filteredCounties.slice(15, 19),
      ];
    },
  },
};
</script>
