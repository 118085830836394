var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-[#33495d] py-14 is-hidden-mobile"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline",staticStyle:{"margin":"0"}},[_c('div',{staticClass:"column is-full justify-center align-middle flex-1 flex flex-col"},[_c('div',{staticClass:"flex align-middle justify-center pb-3 text-5xl"},[_c('IconComponent',{staticClass:"text-white",attrs:{"icon":"map-marker-alt"}})],1),_c('div',{staticClass:"flex align-middle justify-center text-white text-2xl"},[_vm._v(" "+_vm._s(_vm.$t("personList.cities"))+" ")])]),_c('div',{staticClass:"column is-one-quarter pt-4"},[(_vm.profession)?_c('NuxtLink',{staticClass:"flex text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mb-4",attrs:{"to":_vm.localePath({
              name: 'professionals',
              params: { profession: _vm.profession.seoName, city: 'budapest' },
            })}},[_vm._v(" "+_vm._s(_vm.$t("personList.budapest"))+" ")]):_c('NuxtLink',{staticClass:"flex text-2xl font-bold text-[#c7e6ff] hover:text-[#c7e6ff] mb-4",attrs:{"to":_vm.localePath({
              name: 'countyCity',
              params: { county: 'pest-megye', city: 'budapest' },
            })}},[_vm._v(" "+_vm._s(_vm.$t("personList.budapest"))+" ")]),_vm._l((_vm.counties1),function(county){return _c('div',{key:county.countySeoName},[_c('CityLink',{attrs:{"county":county,"profession":_vm.profession}})],1)})],2),_vm._l((_vm.restOfCounties),function(countyList,index){return _c('div',{key:index,staticClass:"column is-one-quarter pt-4"},_vm._l((countyList),function(county){return _c('div',{key:county.countySeoName},[_c('CityLink',{attrs:{"county":county,"profession":_vm.profession}})],1)}),0)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }